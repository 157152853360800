<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Add a consolidated payment request</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-look-up-customer
          v-if="!customerFound"
          @populateCustomer="populateCustomer" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        {{ customer.name }}
        <v-simple-table fixed-header height="400px">
          <thead>
            <tr>
              <th>Invoice number</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in customer.invoices" :key="index">
              <td>{{ invoice.invoice_number }}</td>
              <td>{{ invoice.date | tinyDate }}</td>
              <td>{{ invoice.invoice_value | priceInPounds | currency }}</td>
              <td>
                <v-simple-checkbox
                  :ripple="false"
                  v-model="invoice.use"></v-simple-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="save">Save</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LookUpCustomer from '@/components/Quotations/LookupCustomer.vue';
import axios from '../../axios';

export default {
  name: 'AddConsolidatedPayment',
  components: {
    appLookUpCustomer: LookUpCustomer,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      customerFound: false,
      customer: {},
    };
  },
  methods: {
    save() {
      const postData = {};
      postData.customer = this.customer;
      axios.post(`/consolidatedPayments/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$router.push(`/invoices/draft-consolidated-payment/${response.data.consolidatedPaymentId}`);
        });
    },
    populateCustomer(customer) {
      axios.get(`/customers/getWithUnpaidInvoices/${customer.id}.json?token=${this.token}`)
        .then((response) => {
          this.customerFound = true;
          this.customer = response.data.customer;
        });
    },
  }
};
</script>
