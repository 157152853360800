<template>
  <v-row>
    <v-col class="col-12">
      <v-row dense>
        <v-col class="col-4">
          <v-text-field
            clearable
            @keyup="lookUpCustomersDebounced"
            label="Search for a customer"
            v-model="searchTerm"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="foundCustomers.length > 0">
          <v-col class="col-10">
            <v-list>
            <v-list-item
              @click="populateCustomer(findex)"
              v-for="(customer, findex) in foundCustomers" :key="findex">
                {{ customer.name }} {{ customer.address }}
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ComponentsQuotationsLookupCustomer',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      searchTerm: null,
      foundCustomers: [],
    };
  },
  watch: {
    searchTerm(value) {
      if (!value) {
        this.foundCustomers = [];
      }
    }
  },
  methods: {
    lookUpCustomersDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.lookUpCustomers();
      }, 250);
    },
    lookUpCustomers() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 2) {
          const postData = { searchTerm: this.searchTerm };
          axios.post(`/customers/lookup.json?token=${this.token}`, postData)
          .then((response) => {
            if (response.data.customers === false) {
              this.message = response.data.message;
              this.showMessage = true;
            } else {
              this.foundCustomers = response.data.customers;
            }
          });
        }
      }
    },
    populateCustomer(index) {
      const customer = this.foundCustomers[index];
      this.$emit('populateCustomer', customer);
    },
  },
};
</script>
